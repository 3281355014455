<template>
  <div>
    <div class="text-center main-progress" v-if="!dataLoaded">
      <v-progress-circular :size="50" color="primary" indeterminate />
    </div>

    <div class="mx-4" v-else>
      <v-btn
        class="info-block-toggler px-0"
        text
        :ripple="false"
        :title="mini ? 'Показать информацию о цикле' : 'Скрыть информацию о цикле'"
        @click="setInfoBlock"
      >
        <v-icon class="icon">{{ mini ? 'mdi-chevron-double-down' : 'mdi-chevron-double-up' }}</v-icon>
      </v-btn>

      <div
        ref="infoBlock"
        class="info-block"
        :class="[{'mb-2': !mini}, {'hide': mini}]"
      >
        <PageTitle title="Настройки цикла" />

        <div v-if="cycleInfo" class="cycle-info accent--text">
          <p><strong>Цикл:</strong> {{ cycleInfoString }}</p>
          <p>
            <strong>Сроки проведения:</strong> {{ isCycleCanceled ? 'Отменен' : `${cycleFromDate} - ${cycleToDate}` }}
          </p>
        </div>
      </div>

      <v-tabs
        v-if="!isCycleCanceled"
        background-color="transparent"
        class="base-tabs custom-tabs-arrows"
        color="primary"
        grow
        show-arrows
      >
        <v-tab v-for="(tab, i) in tabs" :key="i" :to="tab.to" class="tab">
          {{ tab.text }}
        </v-tab>
      </v-tabs>

      <v-container fluid class="mt-4 pa-0">
        <router-view :infoBlockHeight="infoBlockHeight" />
      </v-container>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import { formatDateReverseFromISO } from '@/scripts'
import PageTitle from '@/components/ui/Title'

export default {
  name: 'Cycle',

  components: {
    PageTitle
  },

  async created() {
    this.cycleId = +this.$route.params.cycleId
    await this.$store.dispatch('user/getCurrent')
    await this.$store.dispatch('department/checkDepartmentInLocalStorage')
    await this.$store.dispatch('cycle/setCycle', this.cycleId)
    this.dataLoaded = true
    this.setInfoBlockHeight()
  },

  mounted() {
    this.setInfoBlockHeight()
    window.addEventListener("resize", this.setInfoBlockHeight)
  },

  destroyed() {
    window.removeEventListener("resize", this.setInfoBlockHeight)
  },

  data: () => ({
    mini: false,
    cycleId: null,
    dataLoaded: false,
    infoBlockHeight: 0
  }),

  computed: {
    ...mapGetters('cycle', ['cycleInfo', 'isCycleCanceled', 'fromDate', 'toDate']),

    tabs() {
      return [
        { text: 'Основная', to: `/cycles/${this.cycleId}/main` },
        { text: 'Календарь', to: `/cycles/${this.cycleId}/calendar` },
        { text: 'Расписание', to: `/cycles/${this.cycleId}/schedule` },
        { text: 'Учет успеваемости', to: `/cycles/${this.cycleId}/accounting` },
        { text: 'Распределение по группам', to: `/cycles/${this.cycleId}/distribution` },
        { text: 'Протоколы', to: `/cycles/${this.cycleId}/protocols` },
        { text: 'Экзаменационная комиссия', to: `/cycles/${this.cycleId}/commission` }
      ]
    },

    cycleInfoString() {
      return `№${this.cycleInfo?.cycleNum} (${this.cycleInfo?.educationType?.educationTypeId}) ${this.cycleInfo?.name}`
    },

    cycleFromDate() {
      return formatDateReverseFromISO(this.fromDate)
    },

    cycleToDate() {
      return formatDateReverseFromISO(this.toDate)
    }
  },

  methods: {
    setInfoBlock() {
      this.mini = !this.mini
      this.setInfoBlockHeight()
    },

    setInfoBlockHeight() {
      this.$nextTick(() => {
        const infoBlock = this.$refs.infoBlock

        if (infoBlock) {
          this.infoBlockHeight = infoBlock.offsetHeight + parseInt(getComputedStyle(infoBlock).marginBottom)
        }
      })
    }
  }
}
</script>

<style scoped>
.main-progress {
  margin-top: 170px;
}

.cycle-info p {
  font-size: 15px;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-block-toggler {
  position: absolute;
  top: -34px;
  right: -9px;
}

.info-block-toggler::before {
  background-color: transparent !important;
}

.info-block-toggler .icon {
  font-size: 20px;
  color: #a6b6be !important;
}

.info-block {
  max-height: 150px;
}

.info-block.hide {
  max-height: 0;
  overflow: hidden;
}
</style>